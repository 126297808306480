<template>
	<div class="text-center">
		<v-btn :fab="isMobile" :outlined="true" :x-small="isMobile" :small="!isMobile" @click="changeTheme">
			<v-icon
				v-if="isMobile"
				color="grey"
				v-text="$vuetify.theme.dark ? 'fas fa-toggle-on' : 'fas fa-toggle-off'"
				x-small
			></v-icon>
			<template v-else>
				{{ this.$vuetify.theme.dark ? 'Light Temaya Geç' : 'Dark Temaya Geç' }}
			</template>
		</v-btn>
		<v-menu offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					:fab="isMobile"
					color="$vuetify.theme.dark ? 'yellow darken-2' : 'indigo darken-1'"
					:small="!isMobile"
					:x-small="isMobile"
					outlined
					class="ma-1"
					v-bind="attrs"
					v-on="on"
				>
					<v-icon :class="{ 'mr-2': !isMobile }" v-text="'fa-chevron-down'" x-small />
					<template v-if="!isMobile">Hızlı Menu</template>
				</v-btn>
			</template>
			<v-list>
				<v-list-item class="my-0" link @click="logOut">
					<v-list-item-title> <v-icon left v-text="'fa-sign-out-alt'" x-small />Güvenli Çıkış</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import { checkPermission } from '@/common/pageUtils';
import { mapActions, mapGetters } from 'vuex';
import { SET_THEME } from '@/store/modules/sideBar.module';
import { LOGOUT } from '@/store/modules/auth.module';

export default {
	name: 'BackOfficeQuickMenu',
	data() {
		return {};
	},
	computed: {
		...mapGetters(['tunnlClientRefreshDate']),
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.generateMenuByPermissions();
		});
	},
	methods: {
		...mapActions({
			setTheme: SET_THEME,
		}),
		changeTheme() {
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
			this.setTheme(this.$vuetify.theme.dark);
		},
		generateMenuByPermissions() {
			const promise = new Promise((resolve) => {
				for (let index = 0; index < this.backofficeQuickMenuItems?.length; index += 1) {
					const menu = this.backofficeQuickMenuItems[index];
					if (typeof menu?.activePermission === 'function') {
						menu.active = menu.activePermission();
					} else if (menu.groupName || menu.viewMethodName) {
						menu.active = checkPermission({
							groupName: menu.groupName,
							methodName: menu.viewMethodName,
						});
					} else menu.active = true;
				}
				resolve();
			});
			promise.then(() => {
				this.backofficeQuickMenuItems = this.backofficeQuickMenuItems?.filter((x) => x.active);
			});
		},
		logOut() {
			this.$store.dispatch(LOGOUT);
		},
	},
};
</script>

<style></style>
