<template>
	<div>
		<v-layout v-if="isAuthenticated">
			<SideBar :key="componentKey" />
			<v-main>
				<v-container fluid>
					<transition name="fade" mode="out-in">
						<router-view :key="$route.params.key" />
					</transition>
				</v-container>
			</v-main>
		</v-layout>
	</div>
</template>
<script>
import EventBus from '@/event-bus';
import SideBar from '@/views/_shared/layout/backoffice/SideBar.vue';
import { INIT_COMPANY } from '@/store/modules/company.module';

import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'BackofficeBase',
	components: { SideBar },
	data() {
		return {
			componentKey: 0,
		};
	},
	computed: {
		...mapGetters(['isAuthenticated', 'isDark', 'isViewUser']),
	},
	beforeDestroy() {
		EventBus.$off('forceRenderBaseComponent');
		clearInterval(this.newRequestNotificationInterval);
	},
	mounted() {
		this.$vuetify.theme.dark = this.isDark;
		this.$nextTick(() => {
			EventBus.$on('forceRenderBaseComponent', this.forceRerender);
			this.initSite();
		});
	},

	methods: {
		...mapActions({
			initCompany: INIT_COMPANY,
		}),
		initSite() {
			this.initCompany();
		},
		forceRerender() {
			this.componentKey += 1;
		},
	},
	watch: {
		isAuthenticated: {
			handler(auth) {
				if (!auth) {
					this.$router.push({ name: 'login' });
				}
			},
		},
	},
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
	transition-property: opacity;
	transition-duration: 0.25s;
}

.fade-enter-active {
	transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}
</style>
