<template>
	<v-app>
		<v-snackbar :timeout="-1" :value="showRequestNotificationSnackbar" bottom right color="red" rounded>
			<v-btn
				class="mr-1 red darken-2"
				width="20"
				height="20"
				fab
				@click.native="showRequestNotificationSnackbar = false"
				depressed
			>
				<v-icon size="10">fas fa-times</v-icon>
			</v-btn>
			Yeni bir talep geldi. Talebi görüntülemek için
			<a style="color:white" @click="gotoRequestsPage"><strong>tıklayınız. </strong></a>
		</v-snackbar>
		<v-app-bar app elevation="0" clipped-left v-if="!videoWall">
			<v-app-bar-nav-icon @click.stop="sidebarMenu = !sidebarMenu"></v-app-bar-nav-icon>
			<v-toolbar-title class="px-0">
				<v-img class="logo" contain :src="getSite.companyLogo" max-height="50" max-width="100" />
			</v-toolbar-title>
			<v-chip
				class="ml-5"
				pill
				x-small
				v-if="!this.$vuetify.breakpoint.smAndDown && appVersion"
				v-text="'v' + appVersion"
			>
			</v-chip>
			<v-spacer></v-spacer>
			<!-- <template v-if="isSuperAdmin">
				<view-as v-if="!this.$vuetify.breakpoint.smAndDown && isViewUser" />
			</template> -->
			<BackOfficeQuickMenu />
		</v-app-bar>
		<v-navigation-drawer
			:class="{ 'grey lighten-5': !$vuetify.theme.dark }"
			app
			bottom
			clipped
			v-model="sidebarMenu"
			:permanent="sidebarMenu"
			:mini-variant="toggleMini"
			:temporary="isMobile"
			v-if="!videoWall"
		>
			<v-list-item @click="toggleMini = !toggleMini">
				<v-list-item-avatar class="mr-2" size="24" :color="avatarColor">
					<v-img v-if="currentUser.photo" src="https://randomuser.me/api/portraits/men/95.jpg"></v-img>
					<span v-else>{{ avatarText }}</span>
				</v-list-item-avatar>
				<v-list-item-content class="text-truncate">
					{{ currentUser.userName }}
				</v-list-item-content>
				<v-btn icon x-small>
					<v-icon @click.stop="toggleMini = !toggleMini" v-text="'fas fa-chevron-left'" color="grey lighten-2"></v-icon>
				</v-btn>
			</v-list-item>
			<v-divider></v-divider>
			<v-list shaped>
				<v-list-item :color="'indigo'" :to="{ name: 'dashboard' }" @click="closeMenu(1)">
					<v-list-item-icon class="mr-1 d-flex justify-center">
						<v-icon size="18" v-text="'fas fa-layer-group'" :class="'teal--text text--lighten-1'"></v-icon>
					</v-list-item-icon>
					<v-list-item-content
						class="font-weight-bold"
						:class="$vuetify.theme.dark ? 'grey--text text--lighten-4' : 'secondary--text text--lighten-2'"
					>
						<v-list-item-title v-text="'Dashboard'"></v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<template v-for="(menu, indexm) in menuItems">
					<v-list-item
						:color="'indigo'"
						v-if="menu.isLink"
						:key="menu.title + 'm' + indexm"
						:to="{ name: menu.route, params: menu.params }"
						@click="closeMenu(1)"
					>
						<v-list-item-icon class="mr-1 d-flex justify-center">
							<v-icon size="18" v-text="menu.action" :class="'teal--text text--lighten-1'"></v-icon>
						</v-list-item-icon>
						<v-list-item-content
							class="font-weight-bold"
							:class="$vuetify.theme.dark ? 'grey--text text--lighten-4' : 'secondary--text text--lighten-2'"
						>
							<v-list-item-title v-text="menu.title"></v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-group
						no-action
						ref="listgroup"
						v-model="menu.open"
						:key="menu.title + indexm"
						v-if="!menu.isLink && menu.active"
						:color="'teal lighten-1'"
						@click="closeMenu(menu.isLink ? 1 : 2)"
					>
						<template v-slot:activator>
							<v-list-item-icon class="mr-1 d-flex justify-center">
								<v-icon size="18" v-text="menu.action" :class="'teal--text text--lighten-1'"></v-icon>
							</v-list-item-icon>
							<v-list-item-content
								class="font-weight-bold"
								:class="$vuetify.theme.dark ? 'grey--text text--lighten-4' : 'secondary--text text--lighten-2'"
							>
								<v-list-item-title v-text="menu.title"></v-list-item-title>
							</v-list-item-content>
						</template>
						<template v-for="(subMenu, index) in menu.subMenuItems">
							<template v-if="!subMenu.subMenuItems">
								<v-list-item
									class="pl-11"
									:color="'indigo'"
									:key="subMenu.title + 's' + index"
									:to="{ name: subMenu.route, params: subMenu.params }"
									:class="$vuetify.theme.dark ? 'grey--text text--lighten-4' : 'secondary--text text--lighten-2'"
									v-if="subMenu.active"
									@click="closeMenu(3)"
								>
									<v-list-item-icon class="mr-1 d-flex justify-center">
										<v-icon size="14" :class="'teal--text text--lighten-3'">{{ subMenu.action }}</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>{{ subMenu.title }}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</template>
							<template v-else>
								<v-list-group
									:key="subMenu.title + index"
									append-icon=""
									no-action
									disabled
									:color="'teal lighten-1'"
									v-if="subMenu.active"
								>
									<template v-slot:activator>
										<v-list-item-icon class="mr-1 ml-4 d-flex justify-center">
											<v-icon size="14" v-text="subMenu.action" :class="'teal--text text--lighten-1'"></v-icon>
										</v-list-item-icon>
										<v-list-item-content
											class="font-weight-bold"
											:class="$vuetify.theme.dark ? 'grey--text text--lighten-4' : 'secondary--text text--lighten-2'"
										>
											<v-list-item-title v-text="subMenu.title"></v-list-item-title>
										</v-list-item-content>
									</template>
								</v-list-group>
								<template v-for="(thirdLevelMenu, tindex) in subMenu.subMenuItems">
									<v-list-item
										class="pl-11"
										:color="'indigo'"
										:key="subMenu.title + thirdLevelMenu.title + tindex"
										:to="{ name: thirdLevelMenu.route, params: thirdLevelMenu.params }"
										:class="'text--lighten-3'"
										v-if="thirdLevelMenu.active && subMenu.active"
										@click="closeMenu(3)"
									>
										<v-list-item-icon class="mr-1 d-flex justify-center">
											<v-icon size="14" :class="'teal--text text--lighten-3'">{{ thirdLevelMenu.action }}</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>{{ thirdLevelMenu.title }}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</template>
							</template>
						</template>
					</v-list-group>
				</template>
			</v-list>
		</v-navigation-drawer>
	</v-app>
</template>
<script>
import EventBus from '@/event-bus';
import { mapGetters, mapActions } from 'vuex';
import { checkPermission, menuItems } from '@/common/pageUtils';
import BackOfficeQuickMenu from '@/views/_shared/layout/backoffice/BackOfficeQuickMenu.vue';
import { SHOW_NOTIFICATION, CLEAR_NEW_REQUEST_NOTIFICATION } from '@/store/modules/common.module';
// import ViewAs from '@/views/_shared/layout/backoffice/ViewAs.vue';

export default {
	name: 'SideBar',
	components: {
		// ViewAs,
		BackOfficeQuickMenu,
	},
	data() {
		return {
			sidebarMenu: true,
			toggleMini: false,
			menuItems,
			newRequestNotificationInterval: null,
			showRequestNotificationSnackbar: false,
		};
	},
	computed: {
		...mapGetters(['currentUser', 'permissions', 'isSuperAdmin', 'appVersion', 'getSite', 'showNewRequesNotification']),
		videoWall() {
			return typeof this.$route.query.wall !== 'undefined';
		},

		avatarColor() {
			return this.$vuetify.theme.dark ? 'secondary lighten-1' : 'secondary white--text';
		},
		mini() {
			return this.toggleMini;
		},
		isMobile() {
			return !this.mini ? this.$vuetify.breakpoint.smAndDown : false;
		},
		avatarText() {
			return this.currentUser.userName?.substring(0, 1) || '';
		},
	},
	beforeDestroy() {
		clearInterval(this.newRequestNotificationInterval);
	},
	mounted() {
		this.$nextTick(() => {
			this.generateMenuByPermissions();
			this.toggleMini = this.$vuetify.breakpoint.smAndDown;
		});
	},
	created() {
		this.checkNewRequestNotification();
	},
	methods: {
		...mapActions({
			setNotification: SHOW_NOTIFICATION,
			clearNewRequestDate: CLEAR_NEW_REQUEST_NOTIFICATION,
		}),
		gotoRequestsPage() {
			this.showRequestNotificationSnackbar = false;

			if (this.$route.name === 'requests') {
				EventBus.$emit('refreshRequestGrid');
				return;
			}

			this.$router.push({ name: 'requests' });
		},
		checkNewRequestNotification() {
			this.setNotification();
			this.newRequestNotificationInterval = setInterval(() => {
				this.setNotification();
			}, 60000);
		},
		closeMenu(type) {
			if (!this.$vuetify.breakpoint.mobile) this.toggleMini = false;
			else if (type === 1 || type === 3) this.toggleMini = true;
			else if (type === 2) this.toggleMini = false;
		},
		generateMenuByPermissions() {
			const promise = new Promise((resolve) => {
				for (let index = 0; index < this.menuItems.length; index += 1) {
					const menu = this.menuItems[index];

					if (typeof menu.activePermission === 'function') {
						menu.active = menu.activePermission();
					} else if (menu.groupName || menu.viewMethodName) {
						menu.active = checkPermission({
							groupName: menu.groupName,
							methodName: menu.viewMethodName,
						});
					} else menu.active = true;

					for (let sindex = 0; sindex < menu.subMenuItems.length; sindex += 1) {
						const secLevelMenu = menu.subMenuItems[sindex];
						if (typeof secLevelMenu.activePermission === 'function') {
							secLevelMenu.active = secLevelMenu.activePermission();
						} else if (secLevelMenu.subMenuItems) {
							secLevelMenu.active = secLevelMenu.active ?? true;
						} else if (secLevelMenu.groupName || secLevelMenu.viewMethodName) {
							secLevelMenu.active = checkPermission({
								groupName: secLevelMenu.groupName,
								methodName: secLevelMenu.viewMethodName,
							});
						}

						if (secLevelMenu.subMenuItems) {
							for (let tindex = 0; tindex < secLevelMenu.subMenuItems.length; tindex += 1) {
								const thrLevelMenu = secLevelMenu.subMenuItems[tindex];
								thrLevelMenu.active = checkPermission({
									groupName: thrLevelMenu.groupName,
									methodName: thrLevelMenu.viewMethodName,
								});
							}
						}
					}
				}
				resolve();
			});
			promise.then(() => {
				this.menuItems = this.menuItems
					.filter((x) => (typeof x.active !== 'undefined' ? x.active : x.subMenuItems.some((s) => s.active)))
					.map((menu) => ({
						...menu,
						active: menu.isLink ?? menu.subMenuItems.some((x) => x.active || true),
					}));
			});
		},
		groupMiniOpen() {
			if (this.mini) this.toggleMini = !this.toggleMini;
		},
	},
	watch: {
		toggleMini: {
			handler(isMenuOpen) {
				if (isMenuOpen) this.menuItems = this.menuItems.map((menu) => ({ ...menu, open: false }));
			},
		},
		showNewRequesNotification: {
			handler(show) {
				if (show) {
					this.showRequestNotificationSnackbar = true;
					this.clearNewRequestDate();
				}
			},
		},
	},
};
</script>

<style lang="scss" scoped>
.logo {
	.v-image__image {
		background-position: left center !important;
	}
}

.theme--light.v-application ::-webkit-scrollbar {
	width: 5px;
}

.theme--light.v-application ::-webkit-scrollbar-track {
	background: #dbdbdb;
	border-left: 1px solid #dadada;
}

.theme--light.v-application ::-webkit-scrollbar-thumb {
	background: #f3f3f3;
	border: solid 1px #e6e6e6;
	border-radius: 7px;
}

.theme--light.v-application ::-webkit-scrollbar-thumb:hover {
	background: #9e9e9e;
}

.theme--dark.v-application ::-webkit-scrollbar {
	width: 5px;
}

.theme--dark.v-application ::-webkit-scrollbar-track {
	background: #474747;
	border-left: 1px solid #363636;
}

.theme--dark.v-application ::-webkit-scrollbar-thumb {
	background: #5a5a5a;
	border: solid 1px #474747;
	border-radius: 7px;
}

.theme--dark.v-application ::-webkit-scrollbar-thumb:hover {
	background: #9e9e9e;
}
</style>
